<template>
    <div>
        <!--精彩推荐-->
<!--        <div class="recommend rich-text" v-html="recommend.detail"></div>-->
        <div class="grey-bg">
            <div class="rich-text message text-justify-t" v-html="recommend.detail">
            </div>
        </div>
    </div>

</template>

<script>
export default {
name: "recommend",
    data(){
        return {
            recommend: {}
        }
    },
    created() {
        this.getRecommend()
    },
    methods: {
        getRecommend(){
            let url = this.$api.Recommends + '/' + this.$route.params.id
            this.$http.get(url,true).then( res => {
                if(res.data.success){
                    this.recommend = res.data.data
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
 .recommend{
   :deep(img){
         display: block;
         width: 100%;
     }
 }
</style>